import Vue from 'vue'
// import XLSX from 'xlsx'
// import { TYPE } from "vue-toastification"

Vue.prototype.$makeToast = function (
  mensajeColor,
  mensajeCabecera,
  mensajeCuerpo,
  posicion = null
) {
  if (posicion == null) {
    posicion = 'b-toaster-top-right'
  }
  this.$bvToast.toast(mensajeCuerpo, {
    title: mensajeCabecera,
    variant: mensajeColor,
    solid: true,
    toaster: posicion,
    appendToast: true,
  })
}

Vue.prototype.$sendToast = function (tipo, texto, posicion = null) {
  if (posicion == null) {
    posicion = 'top-right'
  }
  this.$toast(texto, {
    type: tipo,
    timeout: 5000,
    position: posicion,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    closeButton: 'button',
    icon: true,
    rtl: false,
  })
}

Vue.prototype.$validateState = function (ref) {
  if (
    this.veeFields[ref] &&
    (this.veeFields[ref].dirty || this.veeFields[ref].validated)
  ) {
    return !this.errors.has(ref)
  }
  return null
}

/* Vue.prototype.$makeExcel = function(endpoint,filename) {
	axios.get(endpoint)
			.then(response => {
            	this.dataToExport=response.data.data
            	let data = XLSX.utils.json_to_sheet(this.dataToExport)
            	const workbook = XLSX.utils.book_new()            	
            	XLSX.utils.book_append_sheet(workbook, data, filename)
            	XLSX.writeFile(workbook, `${filename}.xlsx`)            
          })
          .catch((error) => {
            this.$makeToast("danger","Error de Conexion", "Revise su conexion y presione F5 para reintentar")
         })

  Vue.prototype.$makeToast = function(mensajeColor,mensajeCabecera,mensajeCuerpo,posicion = null) {
	if(posicion == null){
		posicion = 'b-toaster-top-right'
	}
	this.$bvToast.toast(mensajeCuerpo, {
		          title: mensajeCabecera,
		          variant: mensajeColor,
		          solid: true,
		          toaster:posicion ,
		          appendToast: true,          
	})
}	
} */
