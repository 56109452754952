export default {
  listado: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Auth/Login'),
    },
    {
      path: '/install',
      name: 'install',
      component: () => import('@/views/Install'),
    },
    {
      path: '/',
      redirect: { name: 'Inicio' },
      name: 'Principal',
      component: () => import('@/layouts/Container'),
      meta: { requiresAuth: true },
      children: [
        {
          path: 'dashboard',
          name: 'Inicio',
          component: () => import('@/views/Dashboard/Index'),
        },
        {
          path: 'tickets',
          name: 'tickets',
          component: () => import('@/views/Tickets/TicketsTable'),
        },
        {
          name: 'Escanear QR',
          path: 'escanear',
          component: () => import('@/views/Tickets/TicketsQr'),
        },
        {
          name: 'Cargar codigo',
          path: 'codigo',
          component: () => import('@/views/Tickets/TicketsCodigo'),
        },
        {
          path: 'changepass',
          name: 'Cambiar Contraseña',
          component: () => import('@/views/Auth/ChangePass'),
        },
        {
          path: 'requerir',
          name: 'Requerir',
          redirect: { name: 'InscripcionInicial' },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '',
              name: 'RequerirInicial',
              component: () => import('@/views/Tickets/Requerir'),
            },
            {
              path: 'respuesta',
              name: 'RequerirFinal',
              component: () => import('@/views/Tickets/RequerirFinal'),
              props: true,
            },
          ],
        },
        {
          path: 'lotes',
          name: 'Lotes',
          component: () => import('@/views/Lotes/Index'),
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/PageNotFound'),
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/Auth/Logout'),
    },
  ],
}
