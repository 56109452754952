import axios from 'axios'
import store from '@/store'
import router from '@/router'

const baseUrl = process.env.VUE_APP_BASE_URL
// Creo instancia
const API = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
})

// Interceptores
API.interceptors.request.use(
  (config) => {
    const token = store.getters.getToken
    if (token) {
      // console.log(token)
      config.headers.Authorization = `Bearer ${token}`
    } else {
      // console.log('no hay token')
      delete API.defaults.headers.common.Authorization
    }
    return config
  },
  (error) => Promise.reject(error)
)
API.interceptors.response.use(
  (response) => {
    // console.log('response ok')
    return response
  },
  (error) => {
    console.log(error.response.status)
    if (error.response.status === 401) {
      console.log('error 401 - saliendo...')
      store
        .dispatch('cleanLog')
        .then(() => {
          router.push('/login').catch(() => {})
        })
        .catch((error) => {
          router.push('/login').catch(() => {})
          console.log(error)
        })
    }
    return Promise.reject(error)
  }
)

export { API }
